import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthlyOrderById } from "../../apis/order";
import { IGetMonthlyOrderResponse } from "../../apis/order/type";
import SorryImg from "../../assets/images/sorry.png";
import OrderDetailCard from "../../components/orderDetailCard";
import { getAmountUnitText } from "../../helper";

const PaymentResult: React.FC = () => {
  const { state } = useLocation();
  const orderId = state?.orderId;
  const navigate = useNavigate();
  const [order, setOrder] = useState<IGetMonthlyOrderResponse | "fail">();

  const fetchOrderDetails = async (orderId: string) => {
    try {
      const orderResult = await getMonthlyOrderById(+orderId);

      if (orderResult.id) {
        setOrder(orderResult);
      } else {
        setOrder("fail");
      }
    } catch (error) {
      setOrder("fail");
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    } else {
      navigate("/");
    }
  }, [orderId]);

  const unitText =
    order && order !== "fail"
      ? getAmountUnitText(
          order.monthlyOrderItem.interval,
          order.monthlyOrderItem.interval_count
        )
      : "-";

  const isSubcriptionSuccess = order !== "fail";

  return (
    <Stack justifyContent="center" alignItems="center" gap={2}>
      {!isSubcriptionSuccess && <img src={SorryImg} />}
      <Typography variant="h5">
        {isSubcriptionSuccess ? "已成功訂閱!" : "訂閱失敗!"}
      </Typography>
      <Typography variant="h6" color="white">
        {isSubcriptionSuccess ? "請查看您的電郵。" : "請聯絡客服 3611 7688。"}
      </Typography>
      {isSubcriptionSuccess && (
        <OrderDetailCard
          name={order?.u_name}
          email={order?.u_email}
          phone={order?.u_tel}
          carPlate={order?.u_lpn}
          parkingName={order?.parking.display_name}
          parkingAddress={order?.parking.location}
          startDate={order?.start_date}
          endDate={
            order?.monthly_order_state_id === 1
              ? dayjs(order?.end_date)
                  .add(1, "month")
                  .utc()
                  .format("YYYY-MM-DD")
              : dayjs(order?.end_date).utc().format("YYYY-MM-DD")
          }
          amount={order?.price!}
          amountUnit={unitText}
          interval_count={order?.monthlyOrderItem.interval_count}
          subscriptionItem={order?.monthlyOrderItem.display_name}
          octopus_number={order?.octopus_number}
        />
      )}
    </Stack>
  );
};

export default PaymentResult;

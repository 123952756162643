import { HTMLAttributes } from "react";

import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface IStepThreeProps {
  selectedDate: Date;
  dateOnChange: (date: Date) => void;
  defaultStartDate?: Date;
  periodDays?: number;
}

const StepThree = (props: IStepThreeProps) => {
  const { selectedDate, dateOnChange, defaultStartDate, periodDays } = props;

  const DAYS = [];

  for (let i = 0; i <= (periodDays ?? 7) - 1; i++) {
    DAYS.push(dayjs(defaultStartDate).add(i, "days"));
  }

  return (
    <Stack gap={2} my={2}>
      <Typography variant="h6" color="white">
        {"選擇開始日期"}
      </Typography>

      <StyledSwiper
        slidesPerView={7}
        spaceBetween={10}
        freeMode={true}
        modules={[Scrollbar, FreeMode]}
        scrollbar={{
          hide: false,
          draggable: true,
        }}
      >
        {DAYS.map((day, index) => {
          const _isSelected = dayjs(selectedDate).isSame(dayjs(day), "d");
          return (
            <SwiperSlide key={index}>
              <DateBox
                style={{ display: "flex", flexDirection: "column" }}
                sameday={_isSelected ? 1 : 0}
                onClick={() => dateOnChange(dayjs(day).toDate())}
              >
                <Typography variant="body1" fontWeight="bold">
                  {dayjs(day).format("DD")}
                </Typography>
                <Typography variant="body2">
                  {dayjs(day).format("MMM")}
                </Typography>
              </DateBox>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>

      <Stack flexDirection="column" gap={1}>
        <Stack>
          <Typography fontWeight="bold">起租日</Typography>
          <Typography fontWeight={900} color="white">
            {dayjs(selectedDate).format("YYYY年MM月D日")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

type TDateBoxStyleProps = HTMLAttributes<"div"> & {
  sameday: 1 | 0;
};

const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  .swiper-scrollbar {
    height: 4px;
    border-radius: 8px;
  }
  .swiper-scrollbar-drag {
    background-color: #fac80b;
  }
`;

const DateBox = styled.div<TDateBoxStyleProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${(props) => (props.sameday ? "black" : "white")};
  background-color: ${(props) => (props.sameday ? "#fac80b" : "")};
  border-radius: 8px;
  max-width: 50px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: #fac80b;
  }
`;

export default StepThree;

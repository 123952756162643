import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { getParkingItems } from "../../apis/parking";
import GalleryPreviewModal from "../galleryPreviewModal";
import { TOMMOROW } from "../stepperContainer";
import CarTypeCard from "./CarTypeCard";
import FulledCarTypeCard from "./FulledCarTypeCard";
const AWS_GLOBAL_IMAGE_URL = process.env.REACT_APP_AWS_GLOBAL_IMAGES_BUCKET_URL;
interface IStepTwoProps {
  carParkId: string;
  orderItemId: string;
  carParkTitle: string;
  onOtherItemSelect: (detail: ICardTypeDetails) => void;
  galleryList?: string;
}

export interface ICardTypeDetails {
  orderItemId: string;
  categoryId: string;
  itemTypeId: string;
  parkingId: string;
  title: string;
  price: number;
  isFull: boolean;
  visible: boolean;
  deleted: boolean;
  quota: number;
  priceId: string;
  interval: string;
  interval_count: number;
  default_start_date?: Date;
  period_days?: number;
}

const StepTwo = (props: IStepTwoProps) => {
  const {
    carParkId,
    orderItemId,
    carParkTitle,
    onOtherItemSelect,
    galleryList,
    ...otherProps
  } = props;
  const [otherItemDetail, setOtherItemDetail] =
    useState<Array<ICardTypeDetails>>();

  const [showGallery, setShowGallery] = useState(false);

  const getOtherItemDetailById = async (carParkId: string) => {
    try {
      const response = await getParkingItems(carParkId);
      if (response) {
        const responseAsState = response.map((item) => {
          const now = new Date();
          const startDate = new Date(item.start_date);
          const isDefaultStartDateIsBeforeToday = startDate >= now;
          const finalStartDate =
            item.start_date && isDefaultStartDateIsBeforeToday
              ? dayjs(item.start_date, "YYYY-MM-DD").toDate()
              : TOMMOROW;
          return {
            orderItemId: item.id.toString(),
            categoryId: item.item_category_id.toString(),
            itemTypeId: item.item_type_id.toString(),
            parkingId: item.parking_id.toString(),
            title: `${item.display_name} ${item.description ? `(${item.description})` : item.description}`,
            price: item.price,
            isFull: item.available <= 0,
            visible: item.visible,
            deleted: item.deleted,
            quota: item.available,
            priceId: item.stripePriceId,
            interval: item.interval,
            interval_count: item.interval_count,
            default_start_date: finalStartDate,
            period_days: item.maximum_period || 7,
          };
        });

        setOtherItemDetail(responseAsState);
      }
    } catch (error) {
      // TODO error handling
    }
  };

  const swiperData = galleryList
    ?.split(",")
    .map((image) => `${AWS_GLOBAL_IMAGE_URL}/${image}`);

  const handleModalOpen = useCallback(() => {
    setShowGallery(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowGallery(false);
  }, []);

  useEffect(() => {
    getOtherItemDetailById(carParkId);
  }, [carParkId]);

  return (
    <StepTwoContainer {...otherProps}>
      <Box
        sx={{
          my: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{carParkTitle}</div>

        {swiperData && (
          <IconButton onClick={handleModalOpen}>
            <CollectionsOutlinedIcon style={{ fill: "white" }} />
          </IconButton>
        )}
      </Box>
      {(otherItemDetail &&
        otherItemDetail.length > 0 &&
        otherItemDetail?.map((detail) => {
          const _isSelected = detail.orderItemId === orderItemId;
          const show = detail.visible && !detail.deleted;
          if (show) {
            if (detail.isFull) {
              return (
                <FulledCarTypeCard
                  isSelected={_isSelected}
                  key={detail.orderItemId}
                  otherItemDetail={detail}
                  onOtherItemSelect={() => {
                    onOtherItemSelect(detail);
                  }}
                />
              );
            } else {
              return (
                <CarTypeCard
                  isSelected={_isSelected}
                  key={detail.orderItemId}
                  otherItemDetail={detail}
                  onOtherItemSelect={() => onOtherItemSelect(detail)}
                />
              );
            }
          }
        })) || (
        <Typography variant="h6" color="white">
          沒有可用的選項
        </Typography>
      )}

      {swiperData && swiperData?.length > 0 && (
        <GalleryPreviewModal
          open={showGallery}
          onClose={handleModalClose}
          componentsProps={{
            backdrop: {
              style: { backgroundColor: "rgba(0, 0, 0, 0.5)" }, // Adjust the opacity here
            },
          }}
          swiperData={swiperData}
        />
      )}
    </StepTwoContainer>
  );
};

const StepTwoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export type TCarTypeCardStyleProps = HTMLAttributes<"div"> & {
  selected: boolean;
};
export default StepTwo;

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { EffectFade, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import styled from "styled-components";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "swiper/css/scrollbar";
import { SwiperOptions } from "swiper/types/swiper-options";

interface IGallerySwiperProps {
  slideObject: string[];
}

const GallerySwiper = (props: IGallerySwiperProps & SwiperOptions) => {
  const { slideObject, ...otherProps } = props;
  return (
    <SwiperContainer>
      <StyledSwiper
        autoHeight={true}
        slidesPerView={1}
        loop={true}
        centeredSlides={true}
        navigation={true}
        pagination={{
          clickable: true,
          el: ".custom-pagination",
        }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        modules={[Navigation, Pagination, EffectFade]}
        {...otherProps}
      >
        {slideObject.map((url, index) => {
          return (
            <SwiperSlide
              style={{
                display: "flex",
                justifyContent: "center",
                maxHeight: "70vh",
                overflow: "hidden",
              }}
              key={index}
            >
              <img style={{ objectFit: "contain" }} src={url} />
            </SwiperSlide>
          );
        })}
        <div className="custom-pagination"></div>
      </StyledSwiper>
    </SwiperContainer>
  );
};

const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70vh; /* Set a fixed height for the container */
`;

const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .swiper-button-next,
  .swiper-button-prev {
    color: #fac80b; /* Change this to your desired color */
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 32px; /* Adjust the size if needed */
  }

  .custom-pagination {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.7;
  }

  .swiper-pagination-bullet-active {
    background: #fac80b;
    opacity: 1;
  }
`;

export default GallerySwiper;

import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { FC } from "react";

import styled from "styled-components";
import { ReactComponent as Checked } from "../../assets/images/check_circle_black_36dp.svg";
import { ReactComponent as EmptyCheck } from "../../assets/images/radio_button_unchecked_black_36dp.svg";
import { ICarParkInfo } from "../stepperContainer/type";

type TStepOneProps = {
  carParkInfo: Array<ICarParkInfo>;
  onCarParkSelect: (id: string) => void;
  selectedCarParkId: string;
};

const StepOne: FC<TStepOneProps> = (item) => {
  let { onCarParkSelect, selectedCarParkId, carParkInfo } = item;

  carParkInfo = carParkInfo.sort(
    (a, b): any =>
      new Date(b.createdAt).setHours(0, 0, 0, 0) -
      new Date(a.createdAt).setHours(0, 0, 0, 0)
  );

  return (
    <div>
      {carParkInfo.length > 0 && (
        <ImageList gap={20} cols={2}>
          {carParkInfo.map((item) => {
            const _isSelected = item.carParkId === selectedCarParkId;

            if (item.visible && !item.deleted) {
              return (
                <CustomImageListItem
                  key={item.carParkUUId}
                  onClick={() => onCarParkSelect(item.carParkId)}
                >
                  <img
                    src={item.carParkImage}
                    alt={item.carParkTitle}
                    style={{
                      position: "relative",
                      aspectRatio: "auto",
                      height: 140,
                      maxHeight: 140,
                    }}
                  />
                  <div
                    style={{ position: "absolute", top: "2px", right: "2px" }}
                  >
                    {_isSelected ? <Checked /> : <EmptyCheck />}
                  </div>

                  <ImageListItemBar
                    title={item.carParkTitle}
                    subtitle={item.carParkRegionName}
                    position="below"
                    color="white"
                    sx={{
                      paddingX: "8px",
                      ".MuiImageListItemBar-subtitle": {
                        color: "white",
                        mt: "4px",
                      },
                      ".MuiImageListItemBar-title": {
                        whiteSpace: "wrap",
                      },
                    }}
                  />
                </CustomImageListItem>
              );
            }
          })}
        </ImageList>
      )}
    </div>
  );
};

const CustomImageListItem = styled(ImageListItem)`
  border: 1px solid #fac80b;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  > img {
    transition: all 0.3s ease-out;
    transition-duration: 0.3s;
  }
  &:hover {
    > img {
      transform: scale(1.1);
    }
  }
`;

export default StepOne;

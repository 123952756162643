import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, type ModalProps } from "@mui/material";
import React from "react";
import styled from "styled-components";
import GallerySwiper from "../gallerySwiper";

interface IGalleryPreviewModal {
  swiperData: string[];
}

type TGalleryPreviewModalProps = IGalleryPreviewModal &
  Omit<ModalProps, "children">;

const GalleryPreviewModal: React.FC<TGalleryPreviewModalProps> = (props) => {
  const { swiperData, ...otherProps } = props;

  const onClose = () => {
    if (otherProps.onClose) {
      otherProps.onClose({}, "backdropClick");
    }
  };

  return (
    <Modal {...otherProps}>
      <Box sx={modalStyle}>
        <CloseButton onClick={onClose}>
          <CloseIcon style={{ fill: "white", width: "32px", height: "32px" }} />
        </CloseButton>
        <GallerySwiper slideObject={swiperData} />
      </Box>
    </Modal>
  );
};

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",

  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};
const CloseButton = styled(Button)`
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  color: white;
`;

export default GalleryPreviewModal;

import api from "..";
import {
  ICreateSubscriptionResquest,
  type ICreateCustomerPortalResponse,
  type ISubscriptionCardInfoResponse,
  type TCreateSubscriptionResponse,
} from "./type";

export const initStripeIntents = async (
  email: string,
  name: string,
  phone: string
) => {
  const response = await api.post(`/subscriptions/create-setup-intents`, {
    email: email,
    name: name,
    phone: phone,
  });

  return response.data;
};

export const createSubscriptionAndOrder = async ({
  priceId,
  email,
  name,
  phone,
  paymentMethodId,
  paymentIntentId,
  startDate,
  carPlate,
  monthlyOrderItemId,
  customerId,
  type,
  octopus_number,
  wl_token,
}: ICreateSubscriptionResquest) => {
  const response = await api.post<TCreateSubscriptionResponse>(
    `/subscriptions/create-subscription`,
    {
      priceId: priceId,
      email: email,
      name: name,
      tel: phone,
      lpn: carPlate,
      paymentMethodId: paymentMethodId,
      paymentIntentId: paymentIntentId,
      sub_start_date: startDate,
      monthlyOrderItemId: monthlyOrderItemId,
      customerId: customerId,
      type: type,
      octopus_number,
      wl_token,
    }
  );

  return response.data;
};

export const deleteSubscription = async (subscriptionId: string) => {
  const response = await api.post(
    `/subscriptions/unsubscription/${subscriptionId}`
  );

  return response.data;
};

export const updateSubscriptionPaymentMethod = async (
  subscriptionId: string,
  paymentMethodId: string
) => {
  const response = await api.post(
    `/subscriptions/update-subscription-payment-method`,
    {
      subscriptionId: subscriptionId,
      paymentMethodId: paymentMethodId,
    }
  );

  return response.data;
};

export const getSubscriptionCardInfo = async (subscriptionId: string) => {
  const response = await api.get<ISubscriptionCardInfoResponse>(
    `/subscriptions/card-number/${subscriptionId}`
  );

  return response.data;
};

export const createCustomerPortal = async (customerId: string) => {
  const response = await api.post<ICreateCustomerPortalResponse>(
    "/subscriptions/create-customer-portal",
    {
      customerId: customerId,
    }
  );
  return response.data;
};

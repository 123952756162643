import api from "..";
import type {
  ICreateWaitingListRequset,
  ICreateWaitingListResponse,
  IGetWaitingListStatusResponse,
} from "./type";

export const createWaitingList = async ({
  parking_id,
  monthly_order_item_id,
  u_name,
  u_email,
  u_tel,
  u_lpn,
  octopus_number,
}: ICreateWaitingListRequset) => {
  const response = await api.post<ICreateWaitingListResponse>(`/waiting_list`, {
    parking_id,
    monthly_order_item_id,
    u_name,
    u_email,
    u_tel,
    u_lpn,
    octopus_number,
  });

  return response.data;
};

export const getWaitingListByToken = async (token: string) => {
  const response = await api.get<IGetWaitingListStatusResponse>(
    `/waiting_list/token/${token}`
  );
  return response.data;
};

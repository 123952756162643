import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { HTMLAttributes } from "react";
import styled from "styled-components";

interface IOrderDetailCard {
  name?: string;
  carPlate?: string;
  phone?: string;
  email?: string;
  parkingName?: string;
  parkingAddress?: string;
  startDate?: string;
  endDate?: string;
  amount?: number;
  amountUnit?: string;
  interval_count?: number;
  subscriptionItem?: string;
  octopus_number?: string;
}

type TOrderDetailCardProps = IOrderDetailCard & HTMLAttributes<HTMLDivElement>;

const OrderDetailCard: React.FC<TOrderDetailCardProps> = (props) => {
  const {
    name,
    carPlate,
    phone,
    email,
    parkingName,
    parkingAddress,
    startDate,
    endDate,
    amount,
    amountUnit,
    interval_count,
    subscriptionItem,
    octopus_number,
    ...otherProps
  } = props;

  const startDateText = dayjs(startDate).isValid()
    ? dayjs(startDate).format("YYYY年MM月DD日 HH:mm")
    : "";

  const endDateText = dayjs(endDate).isValid()
    ? dayjs(endDate).format("YYYY年MM月DD日 HH:mm")
    : "";

  return (
    <ConfirmWrapper>
      <Stack direction="row">
        {name && (
          <FlexContainer>
            <CustomLabel>車主名稱</CustomLabel>
            <Typography>{name}</Typography>
          </FlexContainer>
        )}
        {carPlate && (
          <FlexContainer>
            <CustomLabel>車牌</CustomLabel>
            <Typography>{carPlate}</Typography>
          </FlexContainer>
        )}
      </Stack>
      <Stack direction="row">
        {phone && (
          <FlexContainer>
            <CustomLabel>電話</CustomLabel>
            <Typography>{phone}</Typography>
          </FlexContainer>
        )}
        {email && (
          <FlexContainer>
            <CustomLabel>電郵</CustomLabel>
            <Typography>{email}</Typography>
          </FlexContainer>
        )}
      </Stack>

      <Stack direction="row">
        {parkingName && (
          <FlexContainer>
            <CustomLabel>停車場名稱</CustomLabel>
            <Typography>{parkingName}</Typography>
          </FlexContainer>
        )}
        {parkingAddress && (
          <FlexContainer>
            <CustomLabel>停車場地址</CustomLabel>
            <Typography>{parkingAddress}</Typography>
          </FlexContainer>
        )}
      </Stack>

      <Stack direction="row">
        {octopus_number && (
          <FlexContainer>
            <CustomLabel>八達通編號</CustomLabel>
            <Typography>{octopus_number}</Typography>
          </FlexContainer>
        )}
      </Stack>

      <Stack direction="row">
        <FlexContainer>
          <CustomLabel>租用項目</CustomLabel>

          <Typography>{subscriptionItem}</Typography>
        </FlexContainer>
        <FlexContainer>
          <CustomLabel>起租日期</CustomLabel>

          <Typography>{startDateText}</Typography>
        </FlexContainer>
      </Stack>
      <Stack direction="row">
        <FlexContainer>
          <CustomLabel>租金費用</CustomLabel>
          {interval_count && interval_count > 1 && (
            <Typography>{`季票計劃(${interval_count}個月)`}</Typography>
          )}
          <Typography>
            {interval_count && interval_count > 1
              ? (amount! / interval_count).toLocaleString("zh-HK", {
                  style: "currency",
                  currency: "HKD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : amount?.toLocaleString("zh-HK", {
                  style: "currency",
                  currency: "HKD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            {amountUnit}
          </Typography>
          {interval_count && interval_count > 1 && (
            <Typography>*需要一次性付款</Typography>
          )}
        </FlexContainer>
      </Stack>
    </ConfirmWrapper>
  );
};

const ConfirmWrapper = styled.div`
  display: flex;
  min-width: calc(100% - 2rem);
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  font-weight: bold;
  gap: 1rem;
  background-color: #37416e;
  color: white;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  word-break: break-all;
`;

const CustomLabel = styled.div`
  font-size: 12px;
  color: #fac80b;
`;

export default OrderDetailCard;

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import {
  A11y,
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

// Import Swiper styles
import { Typography } from "@mui/material";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SwiperOptions } from "swiper/types/swiper-options";

export interface IBannerSlideContent {
  image: string;
  link: string;
  customWidth?: number;
}

interface IBannerSwiperProps {
  slideObject: Array<IBannerSlideContent>;
}

const BannerSwiper = (props: IBannerSwiperProps & SwiperOptions) => {
  const { slideObject, ...otherProps } = props;
  return (
    <>
      <Typography variant="body1" color="white" textAlign="center" mb={1}>
        您可能感興趣的資訊
      </Typography>
      <Swiper
        centeredSlides={true}
        slidesPerView={"auto"}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation, EffectCoverflow, A11y]}
        effect={"coverflow"}
        pagination={true}
        {...otherProps}
      >
        {slideObject.map((slide) => {
          return (
            <SwiperSlide key={slide.link}>
              <SlideContainer href={slide.link} target="_blank">
                <img
                  src={slide.image}
                  style={{ objectFit: "contain", maxWidth: "100%" }}
                />
              </SlideContainer>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

const SlideContainer = styled.a`
  display: flex;
  justify-content: center;
`;

export default BannerSwiper;

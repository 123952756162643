import Box from "@mui/material/Box";
import * as React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Banner2 from "../../assets/images/banner2.png";

import { Modal, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMonthlyOrderItemById, validateCarPlate } from "../../apis/order";
import { verifyOtp } from "../../apis/otp";
import { getParkingDetail } from "../../apis/parking";
import BookingConfirm from "../../components/bookingConfirm";
import PrevSelectedData from "../../components/prevSelectedData";
import StepFour from "../../components/stepFour";
import StepThree from "../../components/stepThree";

import { Bounce, toast } from "react-toastify";
import type { ICardTypeDetails } from "../../components/stepTwo";
import type {
  IBookingDetails,
  ICarParkInfo,
  IErrorMessages,
} from "../../components/stepperContainer/type";
import BannerSwiper from "../../components/swiper";

const TOMMOROW = dayjs().add(1, "day").toDate();

const SpecialEventPage = () => {
  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      title: "",
    },
    { step: 1, title: "" },
    { step: 2, title: "" },
    { step: 3, title: "" },
  ];
  // for entering by scanning qr code
  const [searchParams] = useSearchParams();
  const _event_id = searchParams.get("event_id");

  // state start

  const [isReady, setIsReady] = useState(false);

  // control step state
  const [activeStep, setActiveStep] = useState(0);

  // step one car parking info display
  const [carParkInfo, setCarParkInfo] = useState<ICarParkInfo>();

  // step one selected car park id
  const [selectedCarParkId, setSelectedCarParkId] = useState("");

  // step two selected order item
  const [selectedOrderItem, setSelectedOrderItem] = useState<ICardTypeDetails>({
    orderItemId: "",
    categoryId: "",
    itemTypeId: "",
    isFull: false,
    visible: true,
    deleted: false,
    parkingId: "",
    title: "",
    price: 0,
    quota: 0,
    priceId: "",
    interval: "",
    interval_count: 0,
  });

  // step three selected date
  const [selectedDate, setSelectedDate] = useState<Date>(TOMMOROW);

  // step four booking form
  const [bookingDetails, setBookingDetails] = useState<IBookingDetails>({
    name: "",
    carPlate: "",
    email: "",
    phone: "",
    validationCode: "",
  });

  // step four booking form error message
  const [errorMessages, setErrorMessages] = useState<IErrorMessages>({
    name: "",
    carPlate: "",
    email: "",
    phone: "",
    validationCode: "",
  });

  // step four confirm booking modal
  const [showStepFourConfirmBookingModal, setShowStepFourConfirmBookingModal] =
    useState(false);

  // state end

  // call validate car plate api
  const carPlateValidtor = async () => {
    try {
      const validateResult = await validateCarPlate(
        selectedCarParkId,
        bookingDetails.carPlate
      );
      if (validateResult) {
        if (validateResult.status === "repeat") {
          setShowStepFourConfirmBookingModal(true);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } catch (error) {}
  };

  // get related carpark
  const fetchRelatedParkingInfo = async (pid: string) => {
    try {
      const parkingResponse = await getParkingDetail(pid);
      const data = {
        carParkId: parkingResponse.id.toString(),
        carParkTitle: parkingResponse.display_name,
        parkingAddressText: parkingResponse.location,
        carParkGoogleMapLink: parkingResponse.map,
        carParkImage: parkingResponse.cover,
        createdAt: parkingResponse.createdAt,
        carParkUUId: parkingResponse.car_park_id,
        carParkRegionName: parkingResponse.name,
        visible: parkingResponse.visible,
        deleted: parkingResponse.deleted,
        galleryImages: parkingResponse.gallery_images,
      };
      setCarParkInfo(data);
    } catch (error) {}
  };

  // get event order item by id
  const fetchMonthlyOrderItemDetail = async (oid: string) => {
    try {
      const itemDetail = await getMonthlyOrderItemById(oid);
      if (itemDetail) {
        // set order item state
        console.log(`itemDetail`);
        console.log(itemDetail);
        const now = new Date();
        const startDate = new Date(itemDetail.start_date!);
        const isDefaultStartDateIsBeforeToday = startDate >= now;
        const finalStartDate =
          itemDetail.start_date && isDefaultStartDateIsBeforeToday
            ? dayjs(itemDetail.start_date, "YYYY-MM-DD").toDate()
            : TOMMOROW;
        setSelectedOrderItem({
          orderItemId: itemDetail.id.toString(),
          categoryId: itemDetail.item_category_id.toString(),
          itemTypeId: itemDetail.item_type_id.toString(),
          parkingId: itemDetail.parking_id.toString(),
          title: `${itemDetail.display_name} ${itemDetail.description ? `(${itemDetail.description})` : itemDetail.description}`,
          price: itemDetail.price,
          isFull: itemDetail.available <= 0,
          visible: itemDetail.visible,
          deleted: itemDetail.deleted,
          quota: itemDetail.available,
          priceId: itemDetail.stripePriceId,
          interval: itemDetail.interval,
          interval_count: itemDetail.interval_count,
          default_start_date: finalStartDate,
          period_days: itemDetail.maximum_period || 7,
        });

        await fetchRelatedParkingInfo(itemDetail.parking_id.toString());
        setIsReady(true);
      }
    } catch (error) {
      toast("找不到相關項目", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        type: "error",
      });
    }
  };

  // step four form submit handler
  const stepFourFormSubmitHandler = async (type: "queue" | "booking") => {
    try {
      const canByPass =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "LOCAL";

      if (type === "booking" && canByPass) {
        carPlateValidtor();

        return;
      }
      // bypass flow end

      const verifyOtpResponse = await verifyOtp(
        bookingDetails.phone,
        bookingDetails.validationCode
      );

      if (verifyOtpResponse.message === "success") {
        carPlateValidtor();
      } else {
        setErrorMessages({
          ...errorMessages,
          validationCode: "驗證碼不正確",
        });
      }
    } catch (error) {
      setErrorMessages({
        ...errorMessages,
        validationCode: "驗證碼不正確",
      });
    }
  };

  const handleNext = () => {
    if (activeStep === 2) {
      stepFourFormSubmitHandler("booking");
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const dateOnSelecteHandler = (date: Date) => {
    setSelectedDate(date);
  };

  // step four form input onchange handler
  const formInputOnchangeHandler = (
    key: keyof IBookingDetails,
    value: string
  ) => {
    setBookingDetails({
      ...bookingDetails,
      [key]: value,
    });
  };

  // step four form validator
  const formValidator = (key: keyof IErrorMessages, value: string) => {
    switch (key) {
      case "name":
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, name: "請輸入車主名稱" });
        } else {
          setErrorMessages({ ...errorMessages, name: "" });
        }
        break;
      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, email: "請輸入電郵" });
        } else if (!emailRegex.test(value)) {
          setErrorMessages({ ...errorMessages, email: "不正確的電郵格式" });
        } else {
          setErrorMessages({ ...errorMessages, email: "" });
        }
        break;
      case "carPlate":
        if (value.trim().length === 0) {
          setErrorMessages({
            ...errorMessages,
            carPlate: "請輸入車牌號碼",
          });
        } else {
          setErrorMessages({ ...errorMessages, carPlate: "" });
        }
        break;
      case "phone":
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, phone: "請輸入電話號碼" });
        } else {
          setErrorMessages({ ...errorMessages, phone: "" });
        }
        break;
      case "validationCode":
        if (value.trim().length === 0) {
          setErrorMessages({
            ...errorMessages,
            validationCode: "請輸入認證碼",
          });
        } else {
          setErrorMessages({ ...errorMessages, validationCode: "" });
        }
        break;

      default:
        break;
    }
  };

  // control next button disable or not
  const stepButtonStateController = (): boolean => {
    switch (activeStep) {
      case 0:
        return selectedOrderItem.isFull;
      case 1:
        const hasInvalidFeild =
          errorMessages.name.length > 0 ||
          errorMessages.carPlate.length > 0 ||
          errorMessages.email.length > 0 ||
          errorMessages.phone.length > 0 ||
          errorMessages.validationCode.length > 0;
        const hasEmptyField =
          bookingDetails.name === "" ||
          bookingDetails.carPlate === "" ||
          bookingDetails.email === "" ||
          bookingDetails.phone === "" ||
          bookingDetails.validationCode === "";
        if (
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "LOCAL"
        ) {
          // bypass otp verification
          return false;
        }
        return hasEmptyField || hasInvalidFeild;
      default:
        return false;
    }
  };

  // get slider object content
  const getSlideObject = useCallback(() => {
    let INIT_OBJECT = [{ image: Banner2, link: "" }];

    return INIT_OBJECT;
  }, []);

  // close step four confirm booking modal
  const handleStepFourConfirmBookingModalClose = useCallback(() => {
    setShowStepFourConfirmBookingModal(false);
  }, []);

  // fetch parking list on component mount for step one
  useEffect(() => {
    if (!_event_id) {
      return;
    } else {
      // blocking for not event id
      if (_event_id !== "20" && _event_id !== "21" && _event_id !== "22") {
        toast("不符合活動資格, 將會跳轉至首頁", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          type: "error",
          onClose: () => navigate("/"),
        });
      } else {
        fetchMonthlyOrderItemDetail(_event_id);
      }
    }
  }, [_event_id]);

  // reset selecte date when selected order item
  useEffect(() => {
    setSelectedDate(selectedOrderItem.default_start_date || TOMMOROW);
    setSelectedCarParkId(selectedOrderItem.parkingId);
  }, [selectedOrderItem]);

  return (
    <MainContainer>
      {isReady && (
        <StepWrapper>
          <React.Fragment>
            <Box sx={{ width: "100%" }}>
              {/* select date */}
              {activeStep === 0 && carParkInfo && (
                <>
                  <PrevSelectedData
                    selectedOrderItem={selectedOrderItem}
                    selectedDate={selectedDate}
                    carParkInfo={carParkInfo}
                  />

                  <StepThree
                    selectedDate={selectedDate}
                    dateOnChange={(value: Date) => dateOnSelecteHandler(value)}
                    defaultStartDate={selectedOrderItem.default_start_date}
                    periodDays={selectedOrderItem.period_days}
                  />
                </>
              )}

              {/* reigster */}
              {activeStep === 1 && carParkInfo && (
                <>
                  <PrevSelectedData
                    carParkInfo={carParkInfo}
                    selectedOrderItem={selectedOrderItem}
                    selectedDate={selectedDate}
                  />

                  <StepFour
                    formData={bookingDetails}
                    errorMessages={errorMessages}
                    formValidator={(
                      key: keyof IBookingDetails,
                      value: string
                    ) => formValidator(key, value)}
                    formOnchange={(key: keyof IBookingDetails, value: string) =>
                      formInputOnchangeHandler(key, value)
                    }
                  />
                </>
              )}

              {/* booking confirm */}
              {activeStep === 2 && (
                <BookingConfirm
                  detail={{
                    ...bookingDetails,
                    otherItem: selectedOrderItem.title,
                    parkingAddress: "",
                    parkingName: "",
                    startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                    amount: String(selectedOrderItem.price),
                  }}
                  selectedOrderItem={selectedOrderItem}
                  subscriptionItem={selectedOrderItem.title}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                my: "1rem",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  size="medium"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    mr: 1,
                    ":disabled": {
                      color: "grey",
                    },
                    color: "white",
                  }}
                >
                  上一步
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />

              {activeStep !== steps.length - 2 && (
                <Button
                  size="medium"
                  disabled={stepButtonStateController()}
                  sx={{
                    backgroundColor: "waspYellow",
                    color: "white",
                    ":hover": {
                      backgroundColor: "white",
                      color: "waspYellow",
                    },
                    ":disabled": {
                      backgroundColor: "grey",
                      color: "waspLightYellow",
                    },
                  }}
                  onClick={() => handleNext()}
                >
                  {activeStep === steps.length - 1
                    ? "完成"
                    : activeStep === 3 && selectedOrderItem.isFull
                      ? "預約"
                      : "下一步"}
                </Button>
              )}
            </Box>
          </React.Fragment>
        </StepWrapper>
      )}
      <div>
        <BannerSwiper slideObject={getSlideObject()} />
        <Footer>Powered by AI Parkingbees</Footer>
      </div>

      <Modal
        open={showStepFourConfirmBookingModal}
        onClose={handleStepFourConfirmBookingModalClose}
      >
        <Box sx={modalStyle}>
          <Typography
            color="waspDark"
            textAlign="center"
            variant="h6"
            component="h2"
            mb={4}
          >
            {`您的車牌 ${bookingDetails.carPlate} 已在${carParkInfo?.carParkTitle}租用車位中，確認繼續？`}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              onClick={handleStepFourConfirmBookingModalClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "waspDark",
                color: "white",
                ":hover": {
                  backgroundColor: "waspDark",
                  color: "white",
                },
              }}
            >
              返回
            </Button>
            <Button
              onClick={() => {
                handleStepFourConfirmBookingModalClose();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }}
              variant="contained"
              fullWidth
              sx={{
                color: "white",
                backgroundColor: "waspYellow",
                ":hover": {
                  color: "white",
                  backgroundColor: "waspYellow",
                },
              }}
            >
              確認
            </Button>
          </Stack>
        </Box>
      </Modal>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Footer = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  margin-top: 1rem;
`;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

export default SpecialEventPage;

import api from "..";
import { IGetParkingItemsResponse, IGetParkingListResponse } from "./type";

export const getParkingList = async () => {
  const response = await api.get<IGetParkingListResponse[]>(
    "/parking/all-parking"
  );
  return response.data;
};

export const getParkingDetail = async (id: string) => {
  const response = await api.get<IGetParkingListResponse>(`/parking/${id}`);
  return response.data;
};

export const getParkingItems = async (id: string) => {
  const response = await api.get<IGetParkingItemsResponse[]>(
    `/parking/${id}/monthly_order_items`
  );
  return response.data;
};

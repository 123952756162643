import { Button, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { HTMLAttributes, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { getWaitingListByToken } from "../../apis/waitingList";
import locationIcon from "../../assets/images/location.png";
import SorryImg from "../../assets/images/sorry.png";
import BookingConfirm, {
  IConfirmDetails,
} from "../../components/bookingConfirm";
import MovingCar from "./movingCar";

import { FreeMode, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const TOMMOROW = dayjs().add(1, "day").toDate();

dayjs.extend(relativeTime);
dayjs.extend(duration);

interface IQueueProps extends IConfirmDetails {
  priceId: string;
  orderItemId: string;
  interval: string;
  interval_count: number;
  queueIndex: number;
  map: string;
  expiredDate?: string;
  parkingId: string;
  defaultStartDate?: Date;
  periodDays?: number;
}

const statusMap = {
  1: "pending",
  2: "active",
  3: "completed",
  4: "expired",
} as const;

type TQueueStatus = (typeof statusMap)[keyof typeof statusMap];

export default function Queue() {
  const [queueStatus, setQueueStatus] = useState<TQueueStatus>("pending");
  const [selectedDate, setSelectedDate] = useState(TOMMOROW);
  const [customerData, setCustomerData] = useState<IQueueProps>();
  const [countDown, setCountDown] = useState("");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const _token = searchParams.get("wlt");

  const getQueueStatus = async (token: string) => {
    try {
      const queueResult = await getWaitingListByToken(token);

      // redirect for completed queue
      if (queueResult.waiting_list_items.waiting_list_status_id === 3) {
        navigate("/");
      }
      // control display related content by status
      setQueueStatus(
        statusMap[
          queueResult.waiting_list_items
            .waiting_list_status_id as keyof typeof statusMap
        ]
      );

      if (queueResult) {
        let finalStartDate = TOMMOROW;
        if (queueResult.waiting_list_items.monthlyOrderItem.start_date) {
          const now = new Date();
          const startDate = new Date(
            queueResult.waiting_list_items.monthlyOrderItem.start_date
          );

          if (startDate >= now) {
            finalStartDate = dayjs(
              queueResult.waiting_list_items.monthlyOrderItem.start_date,
              "YYYY-MM-DD"
            ).toDate();
          }
        }

        setCustomerData({
          name: queueResult.waiting_list_items.u_name,
          carPlate: queueResult.waiting_list_items.u_lpn,
          email: queueResult.waiting_list_items.u_email,
          phone: queueResult.waiting_list_items.u_tel,
          parkingAddress: queueResult.waiting_list_items.parking.location,
          map: queueResult.waiting_list_items.parking.map,
          otherItem: `${queueResult.waiting_list_items.monthlyOrderItem.display_name} ${queueResult.waiting_list_items.monthlyOrderItem.description}`,
          startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
          parkingName: queueResult.waiting_list_items.parking.display_name,
          parkingId: queueResult.waiting_list_items.parking.id.toString(),
          amount:
            queueResult.waiting_list_items.monthlyOrderItem.price.toString(),
          priceId:
            queueResult.waiting_list_items.monthlyOrderItem.stripePriceId,
          orderItemId:
            queueResult.waiting_list_items.monthlyOrderItem.id.toString(),
          interval: queueResult.waiting_list_items.monthlyOrderItem.interval,
          interval_count:
            queueResult.waiting_list_items.monthlyOrderItem.interval_count,
          queueIndex: queueResult.canadidate_number,
          expiredDate: queueResult.waiting_list_items.expired_date,
          defaultStartDate: finalStartDate,
          periodDays:
            queueResult.waiting_list_items.monthlyOrderItem.maximum_period || 7,
        });
        setSelectedDate(finalStartDate);
      }
    } catch (error) {}
  };

  const DAYS: any[] = [];
  for (let i = 0; i <= (customerData?.periodDays || 6) - 1; i++) {
    DAYS.push(dayjs(customerData?.defaultStartDate).add(i, "days"));
  }

  // get queue status when token is ready
  useEffect(() => {
    if (_token) {
      getQueueStatus(_token);
    }
  }, [_token]);

  // trigger timer when status is active
  useEffect(() => {
    if (queueStatus !== "active") {
      return;
    }
    console.log("timer start");
    const timer = setInterval(() => {
      const now = dayjs();
      const diff = dayjs(customerData?.expiredDate!).diff(now);

      if (diff <= 0) {
        clearInterval(timer);
        setCountDown("已超時");
      } else {
        const countdown = dayjs.duration(diff);
        const days = countdown.days();
        const hours = countdown.hours();
        const minutes = countdown.minutes();
        const seconds = countdown.seconds();
        setCountDown(`${days}天 ${hours}小時 ${minutes}分鐘 ${seconds} 秒`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [queueStatus]);

  useEffect(() => {
    if (customerData) {
      setCustomerData({
        ...customerData,
        startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
      });
    }
  }, [selectedDate]);

  return (
    <Stack gap={2}>
      {/* pending for booking */}
      {queueStatus === "pending" && (
        <>
          <Typography variant="h4" fontWeight={900}>
            輪候中...
          </Typography>

          <Typography variant="caption" color="white" fontWeight={900}>
            {` 您正在輪候以下停車場車位, 當有車位空缺時,
          我們會以SMS及電郵通知您。(如有需要請保存此網頁連結來查看狀態。)`}
          </Typography>

          <LocationWrapper>
            <Typography
              variant="h4"
              color="white"
              fontWeight="bold"
              mb="0.3rem"
            >
              {customerData?.parkingName}
            </Typography>

            <Link
              variant="h6"
              color={"#ffffff"}
              href={customerData?.map}
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <img src={locationIcon} width={25} height={25} />
              {customerData?.parkingAddress}
            </Link>
            <Typography variant="button" mt={1}>
              {customerData?.otherItem}
            </Typography>
          </LocationWrapper>

          <Stack alignItems="center" justifyContent="center" mt={5}>
            <Typography color="white" fontWeight="bold">
              現時輪候位置:
            </Typography>
            <Typography variant="caption" color="white">
              {`(你亦可以透過刷新頁面來更新即時數據)`}
            </Typography>
            <Typography
              variant="h1"
              fontWeight="900"
              borderBottom="1px solid white"
            >
              {customerData?.queueIndex ?? "--"}
            </Typography>
            <MovingCar />
          </Stack>
        </>
      )}

      {/* ready for booking */}
      {queueStatus === "active" && (
        <>
          <Typography variant="h6" color="white">
            輪到您了！ 請選擇開始日期及核對資料。
          </Typography>
          <Typography
            variant="h5"
            color={"white"}
            display="flex"
            fontWeight="bold"
            justifyContent="center"
            sx={{ display: "flex", alignItems: "baseline" }}
          >
            {countDown}
            {/* {dayjs(countDown).format("HH:mm:ss")}
            <div style={{ fontSize: 14 }}>&nbsp; 秒</div> */}
          </Typography>
          <Typography variant="caption">
            * 請於限時內點擊進行訂閱租用， 否則將自動失去租用優先權。
          </Typography>

          <StyledSwiper
            slidesPerView={7}
            spaceBetween={10}
            freeMode={true}
            modules={[Scrollbar, FreeMode]}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
          >
            {DAYS.map((day, index) => {
              const _isSelected = dayjs(selectedDate).isSame(dayjs(day), "d");
              return (
                <SwiperSlide key={index}>
                  <DateBox
                    style={{ display: "flex", flexDirection: "column" }}
                    sameday={_isSelected ? 1 : 0}
                    onClick={() => setSelectedDate(dayjs(day).toDate())}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {dayjs(day).format("DD")}
                    </Typography>
                    <Typography variant="body2">
                      {dayjs(day).format("MMM")}
                    </Typography>
                  </DateBox>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>

          {customerData && (
            <BookingConfirm
              detail={customerData}
              selectedOrderItem={{
                orderItemId: customerData.orderItemId,
                priceId: customerData.priceId,
                interval: customerData.interval,
                interval_count: customerData.interval_count,
              }}
              subscriptionItem={customerData?.otherItem}
              token={_token!}
            />
          )}
        </>
      )}

      {/* expired for booking */}
      {queueStatus === "expired" && (
        <>
          <img src={SorryImg} />
          <Typography variant="h6" textAlign="center">
            抱歉，您的租用車位時限已過，請重新登記。
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontWeight: 600,
              backgroundColor: "white",
              color: "waspLightDark",
              ":hover": {
                backgroundColor: "waspYellow",
                color: "waspDark",
              },
            }}
            href="/"
          >
            返回
          </Button>
        </>
      )}
    </Stack>
  );
}

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-radius: 4px;
  background-color: #37416e;
`;

type TDateBoxStyleProps = HTMLAttributes<"div"> & {
  sameday: 1 | 0;
};

const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  .swiper-scrollbar {
    height: 4px;
    border-radius: 8px;
  }
  .swiper-scrollbar-drag {
    background-color: #fac80b;
  }
`;

const DateBox = styled.div<TDateBoxStyleProps>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.sameday ? "black" : "white")};
  background-color: ${(props) => (props.sameday ? "#fac80b" : "")};
  border-radius: 8px;
  min-width: 50px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: #fac80b;
  }
`;
